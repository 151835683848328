import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNamesWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { opkevin_initStrategies } from './opkevin_initStrategies';
import { opkevin_initStyling } from './opkevin_initStyling';
import { Opkevin_Col } from './opkevin_Col';
import { Opkevin_SidePane } from './opkevin_SidePane';
import { Opkevin_StrategyGroup } from './opkevin_StrategyGroup';
import { Daddy960_TopBar } from './component/daddy960_TopBar';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { Daddy960_Footer } from './component/daddy960_Footer';
import { Daddy960LoginView } from './component/Daddy960LoginView';
import { fr_me } from '../heineken_template/_fr/fr_me';
import { useSignalrStart2_1 } from '~/modules/SDK/Signalr/useSignalrStart2_1';
export const opkevin_init = (templateProps) => {
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode('dark');
    opkevin_initStyling(templateProps);
    opkevin_initStrategies();
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNamesWithExpiredAt([
        'web_opkevin',
        'web_opkevin_pro',
    ]);
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TX-1',
        interval: 'D',
        enableVolumeIndicator: true,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: false,
        disabledHeaderChartType: false,
        disabledHeaderCompare: false,
        disabledGoToDate: true,
        disabledHeaderSaveload: false,
        overrides: {
            ...store.charting.darkOverrides,
        },
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 36px 1fr 1fr;
    grid-template-columns: 672px 140px 1fr;
    place-content: flex-start;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Col1 Drawer2 Col2'
      'Col1 Drawer2 Chart'
      'Row2 Row2 Row2';

    .Chart {
      height: calc(100vh - 84px);
    }

    ${createIPadCss(css `
      grid-template-rows: 48px 36px 1fr 1fr;
      grid-template-columns: 400px 1fr;
      grid-template-areas:
        'Row1 Row1'
        'Col1 Col2'
        'Col1 Chart'
        'Row2 Row2';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 560px 36px 560px 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Col1 Col1 Col1'
        'Col2 Col2 Col2'
        'Chart Chart Chart'
        'Row2 Row2 Row2';

      .Chart {
        height: 560px;
      }
    `)}
  `;
    templateProps.layout.Row1 = (<Daddy960_TopBar product='opkevin' clientId={[
            { label: '自用樣板', value: 'daddy960' },
            { label: '導航樣板', value: 'daddy960_qq' },
        ]}/>);
    templateProps.layout.Row2 = Daddy960_Footer;
    templateProps.layout.Col1 = Opkevin_Col;
    templateProps.layout.Col2 = Opkevin_StrategyGroup;
    templateProps.layout.Drawer2 = Opkevin_SidePane;
    templateProps.layout.login = (<Daddy960LoginView product='opkevin' faviconUrl='opkevin/favicon.png' lineLink='https://liff.line.me/1645278921-kWRPP32q/?accountId=367wxcpl'/>);
    templateProps.onPageEnter = () => {
        signalrStore2.addQuote(['TX-1', 'TSEA']);
    };
    templateProps.hooks.add(fr_me.useInstall);
    templateProps.hooks.add(useSignalrStart2_1);
};
