import styled from '@emotion/styled';
import { memo, useState } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { store } from '../heineken_template/_private/store';
import IntradayTrendChart from '~/modules/trendChart';
import TitleSymbolQuote from '../../modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { useStockPriceChangeDistributionState } from '../futuresai/stock-price-change-distribution/components/useStockPriceChangeDistributionState';
import { MarketView } from './component/MarketView';
import { css } from '@emotion/react';
import { Chip } from '@mantine/core';
import { horizontalScrollbarCss } from '~/css/scrollbarCss';
import AboveAvgLine from '~/modules/investment-consultant/aboveAvgLine/StockAboveMaChart';
export const Edwin_Col1 = memo(function Edwin_Col1() {
    //const charting = useSnapshot(store).charting
    const [type, setType] = useState('overView');
    return (<styleds.container>
      <styleds.switch.container>
        <styleds.switch.title>圖卡選項</styleds.switch.title>
        <Chip checked={type === 'overView'} onClick={() => setType('overView')} size={'xs'} radius='md' color='yellow' css={chipsCss}>
          漲跌家
        </Chip>
        <Chip checked={type === 'chart'} onClick={() => setType('chart')} size={'xs'} radius='md' color='yellow' css={chipsCss}>
          走勢圖
        </Chip>
        <Chip checked={type === 'market'} onClick={() => setType('market')} size={'xs'} radius='md' color='yellow' css={chipsCss} disabled={true}>
          市場熱度
        </Chip>
      </styleds.switch.container>
      {type === 'chart' && (<styleds.content.container>
          <styleds.content.item>
            <styleds.content.trendChart onClick={() => store.charting.changeSymbol('TSEA')}>
              <TitleSymbolQuote.Default symbol={'TSEA'}/>
              <IntradayTrendChart symbol={'TSEA'} ticksSize={10} ticksHeight={14} priceTicksMargin={-12} priceTicksSize={12}/>
            </styleds.content.trendChart>
          </styleds.content.item>
          <styleds.content.item>
            <styleds.content.trendChart onClick={() => store.charting.changeSymbol('OTCA')}>
              <TitleSymbolQuote.Default symbol={'OTCA'}/>
              <IntradayTrendChart symbol={'OTCA'} ticksSize={10} ticksHeight={14} priceTicksMargin={-20} priceTicksSize={12}/>
            </styleds.content.trendChart>
          </styleds.content.item>
        </styleds.content.container>)}

      {type === 'overView' && (<styleds.content.container>
          <styleds.content.item>
            <useStockPriceChangeDistributionState.Provider>
              <MarketView market='TSEA'/>
            </useStockPriceChangeDistributionState.Provider>
          </styleds.content.item>
          <styleds.content.item>
            <useStockPriceChangeDistributionState.Provider>
              <MarketView market='OTCA'/>
            </useStockPriceChangeDistributionState.Provider>
          </styleds.content.item>
        </styleds.content.container>)}

      {type === 'market' && (<styleds.content.container>
          <styleds.content.market.body>
            <styleds.content.market.item>
              <styleds.content.market.mark color='#00ffff'/>
              站上月線家數
            </styleds.content.market.item>
            <styleds.content.market.item>
              <styleds.content.market.mark color='#ff0000'/>
              站上季線家數
            </styleds.content.market.item>
            <styleds.content.market.item>
              <styleds.content.market.mark color='#3F51B5' css={css `
                  border-radius: 0px;
                `}/>
              加權指數
            </styleds.content.market.item>
            <styleds.content.market.item>
              <styleds.content.market.mark color='#74583a' css={css `
                  border-radius: 0px;
                `}/>
              櫃買指數
            </styleds.content.market.item>
          </styleds.content.market.body>
          <styleds.content.market.chart>
            <AboveAvgLine.display market1='上市' market2='上市' ma1={20} ma2={60} days={60} legend={false}/>
          </styleds.content.market.chart>
          <styleds.content.market.chart>
            <AboveAvgLine.display market1='上櫃' market2='上櫃' ma1={20} ma2={60} days={60} legend={false} symbol='OTCA'/>
          </styleds.content.market.chart>
        </styleds.content.container>)}
    </styleds.container>);
});
const chipsCss = css `
  .mantine-hwb60 {
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 30px;
    font-size: 12px;
    background-color: #25262e;
    padding: 0 4px;
  }
`;
const styleds = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    background-color: #1d2630;
    border-radius: 6px;
    padding: 2px;
    gap: 4px;
  `,
    switch: {
        container: styled.div `
      ${fill_vertical_all_center};
      width: 80px;
      height: 100%;
      gap: 4px;
      background-color: #2a2f3b;
      border-radius: 4px;
      padding: 4px;
    `,
        title: styled.div `
      font-size: 14px;
    `,
    },
    content: {
        container: styled.div `
      ${fill_horizontal_cross_center};
      ${horizontalScrollbarCss};
      width: 100%;
      height: 148px;
      gap: 4px;
    `,
        trendChart: styled.div `
      display: grid;
      grid-template-rows: 26px calc(100% - 32px);
      background-color: #2e323b;
      border-radius: 6px;
      width: 300px;
      height: 140px;
    `,
        item: styled.div `
      width: 300px;
      height: 128px;
      flex-shrink: 0;
    `,
        market: {
            body: styled.div `
        ${fill_vertical_all_center};
        width: 128px;
        min-width: 128px;
        height: 140px;
        font-size: 14px;
        gap: 4px;
      `,
            item: styled.div `
        ${fill_horizontal_all_center};
        height: 28px;
        gap: 4px;
      `,
            mark: styled.div `
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${props => props.color};
      `,
            chart: styled.div `
        width: calc(100% - 128px);
        max-width: 300px;
        height: 140px;
        padding: 4px 8px;
        background-color: #2f323a;
        border-radius: 6px;
      `,
        },
    },
};
